@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Orbitron-SemiBold";
  src: url("../../public/fonts/Orbitron-SemiBold.ttf");
}

@font-face {
  font-family: "Ubuntu-Regular";
  src: url("../../public/fonts/Ubuntu-Regular.ttf");
}
